import React, { useEffect, useState, useRef } from 'react';
import { PageHeader, Button, Dropdown, Menu, Modal } from 'antd';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Icon, Datagrid } from '../../../components';
import mainCountersService from '../../../services/mainCountersService';
import mainCountersHistoryService from '../../../services/mainCountersHistoryService';

import { renderUnit } from '../../../helpers/unitRender';

import Spin from '../../../components/Spin';
import { MainCounterCard } from '../components/MainCounterCard';
import PermisesService from '../../../services/premisesService';
import MainCounterReadModal from '../components/MainCounterReadModal';

const { confirm } = Modal;

const percentRender = (percent) => {
  const value = parseFloat(percent);

  if (!value) {
    return `+0.00%`;
  } else if (value > 0) {
    return `+${value.toFixed(2)}%`;
  } else {
    return `${value.toFixed(2)}%`;
  }
};

const Show = () => {
  const history = useHistory();
  const { id, counterId } = useParams();
  const datagridRef = useRef();
  const [canEdit, setCanEdit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [mainCounterData, setMainCounterData] = useState();
  const [investmentPremises, setInvestmentPremises] = useState([]);
  const [premises, setPremises] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingRead, setEditingRead] = useState();
  const [allReads, setAllReads] = useState([]);

  const columns = [
    {
      title: <span>Stan licznika ({renderUnit(mainCounterData?.unit)})</span>,
      exportTitle: `Stan licznika (${mainCounterData?.unit})`,
      dataIndex: 'value',
      visible: true,
      filterEnabled: false,
      key: 'value',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span>
          {record.mainCounterValueChangePercent !== 0 && (
            <div
              style={{
                position: 'absolute',
                top: 10,
                color:
                  record.mainCounterValueChangePercent < 0
                    ? '#EC434C'
                    : '#15D55E',
                fontSize: 8,
                fontWeight: 700,
              }}
            >
              {percentRender(record.mainCounterValueChangePercent)}
            </div>
          )}

          <div>
            {parseFloat(record.value).toFixed(2)} {renderUnit(record.unit)}
          </div>
        </span>
      ),
    },
    {
      title: 'Stan licznika od',
      filterProps: { addonAfter: renderUnit(mainCounterData?.unit) },
      dataIndex: 'valueFrom',
      visible: false,
      filterEnabled: true,
      key: 'valueFrom',
      sorter: false,
      export: false,
    },
    {
      title: 'Stan licznika do',
      filterProps: { addonAfter: renderUnit(mainCounterData?.unit) },
      dataIndex: 'valueTo',
      visible: false,
      filterEnabled: true,
      key: 'valueTo',
      sorter: false,
      export: false,
    },
    {
      title: (
        <span>
          Różnica od ostatniego odczytu ({renderUnit(mainCounterData?.unit)})
        </span>
      ),
      exportTitle: `Ostatni odczyt (${mainCounterData?.unit})`,
      dataIndex: 'mainCounterValue',
      visible: true,
      filterEnabled: false,
      key: 'mainCounterValue',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (text ? parseFloat(text).toFixed(2) : '-'),
    },
    {
      title: 'Data odczytu',
      dataIndex: 'readDate',
      visible: true,
      filterEnabled: false,
      key: 'readDate',
      sorter: false,
      render: (text, record) => (text ? text : '-'),
      exportRender: (text, record) => (text ? text : '-'),
    },
  ];

  const refresh = () => {
    datagridRef.current.refresh();
  };

  const handleOk = (historyId) => {
    const MainCountersHistoryService = new mainCountersHistoryService(
      id,
      counterId
    );
    MainCountersHistoryService.delete(historyId).then((response) => {
      refresh();
    });
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: `Usunąć wybraną pozycje?`,
      content: 'Po kliknięciu „Usuń”, przywrócenie nie będzie możliwe.',
      okText: 'Usuń',
      okType: 'danger',
      cancelText: 'Anuluj',
      icon: false,
      width: 320,
      className: 'delete-confirm-modal',
      cancelButtonProps: { className: 'button-secondary', size: 'large' },
      okButtonProps: { size: 'large' },
      onOk() {
        handleOk(record.id);
      },
    });
  };

  const menu = (record) => (
    <Menu>
      {
        <Menu.Item
          key='98'
          onClick={() => {
            setEditingRead(record);
            setShowModal(true);
          }}
          icon={<Icon name='edit' style={{ marginRight: '5px' }} />}
        >
          Edytuj
        </Menu.Item>
      }
      {
        <Menu.Item
          key='99'
          style={{ color: '#EC434C' }}
          icon={<Icon name='delete' style={{ marginRight: '5px' }} />}
          onClick={() => showDeleteConfirm(record)}
        >
          Usuń
        </Menu.Item>
      }
    </Menu>
  );

  useEffect(() => {
    const MainCountersService = new mainCountersService(id);
    MainCountersService.getOne(counterId).then((data) => {
      setMainCounterData(data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const MainCountersHistoryService = new mainCountersHistoryService(
      id,
      counterId
    );
    MainCountersHistoryService.getList().then((data) => {
      setAllReads(data.sort((a, b) => (a.readDate > b.readDate ? -1 : 1)));
      setLoading(false);
    });
  }, [showModal]);

  useEffect(() => {
    const PremisesService = new PermisesService(id);

    PremisesService.getList().then((response) => {
      setPremises(response.content);
      setInvestmentPremises(response.content);
    });
  }, []);

  const AddReadButton = () => {
    return (
      <Button
        type='primary'
        onClick={() => setShowModal(true)}
        style={{ marginBottom: 20 }}
      >
        Dodaj odczyt <Icon name='plus' style={{ marginLeft: 10 }} />
      </Button>
    );
  };

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <>
          <div>
            <PageHeader
              ghost={false}
              backIcon={<Icon name='arrow-simply-left' />}
              onBack={() => window.history.back()}
              title={'Licznik główny'}
              extra={[
                <Link
                  key='3'
                  to={`/investment/${id}/counters/${counterId}/subCounters`}
                >
                  <Button className='button-secondary'>
                    Podliczniki <Icon name='history-list' />
                  </Button>
                </Link>,
                <Link
                  key='2'
                  to={`/investment/${id}/counters/${counterId}/edit`}
                >
                  <Button type='primary' className='counters-edit-btn'>
                    Edytuj <Icon name='edit' />
                  </Button>
                </Link>,
              ]}
            >
              <MainCounterCard mainCounterData={mainCounterData} />
            </PageHeader>
          </div>
          <div>
            <div className='subcounter-history-datagrid'>
              <MainCounterReadModal
                isModalVisible={showModal}
                afterCancel={() => {
                  setShowModal(false);
                  setEditingRead(null);
                }}
                allReads={allReads}
                mainCounterData={mainCounterData}
                editingRead={editingRead}
                refresh={refresh}
              />
              <Datagrid
                ref={datagridRef}
                title={'Historia odczytów'}
                exportTitle={'Historia odczytów'}
                resource={`investment/${id}/counters`}
                dataProvider={new mainCountersHistoryService(id, counterId)}
                directResponse={true}
                columns={columns}
                sort={{
                  sortField: 'id',
                  sortOrder: 'descend',
                }}
                hideReset={true}
                hideSms={true}
                hideEmail={true}
                hideEdit
                hideShow
                hideFilters
                customRowActions={[
                  (record) => (
                    <Dropdown
                      overlay={() => menu(record)}
                      trigger='click'
                      placement='bottomCenter'
                      className='smalllist-wrapper-row-action'
                    >
                      <Icon name={'more-vertical'} />
                    </Dropdown>
                  ),
                ]}
                customCreate={<AddReadButton />}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Show;
