import React, { useEffect, useRef, useState } from 'react';
import {
  Modal,
  Dropdown,
  Button,
  Menu,
  Form,
  Input,
  Divider,
  message,
} from 'antd';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import json2csv from 'json2csv';
import moment from 'moment';
import jsPDF from 'jspdf';
// eslint-disable-next-line no-unused-vars
import autoTable from 'jspdf-autotable';
import cloneDeep from 'lodash/cloneDeep';

import { useHistory, useParams } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DownOutlined } from '@ant-design/icons';
import '../../static/Overpass-Regular-normal';
import PaymentsS from '../../services/paymentsService';
import FaultsS from '../../services/faultsService';
import TenantChangesS from '../../services/tenantChangeGroups';
import UserSettlementsGroupS from '../../services/userSettlementsGroupService';
import tranchesService from '../../services/tranchesService';
import Icon from '../Icon';
import PermisesService from '../../services/premisesService';
import FaultGroupService from '../../services/faultGroupService';
import massActionsService from '../../services/massActionsService';
import communityAdminsService from '../../services/communityAdminsService';
import { generateRandomPassword } from '../../helpers/passwordHelpers';
import MessagePopup from '../MessagePopup';
import VendorsService from '../../services/vendorsService';
import ResolutionsService from '../../services/resolutionsService';
import documentsService from '../../services/documentsService';
import PublicFileService from '../../services/publicFileService';

const { confirm } = Modal;

const MassActions = ({
  dataProvider,
  selectedRowKeys,
  resource,
  data,
  columns,
  title = 'Lista',
  canEdit,
  hideDelete,
  hideReset,
  hideSms,
  hideEmail,
  showDownload,
  showAssignOwners,
  showAddCountersReads,
  showSendMembersMessage,
  showSendMembersTemplate,
  showSendPremisesMessage,
  showSendPremisesTemplate,
  isAdmin = true,
  refresh,
}) => {
  const { id } = useParams();
  const history = useHistory();
  const [showSmsPopup, setShowSmsPopup] = useState(false);
  const [showEmailPopup, setShowEmailPopup] = useState(false);

  const handleOk = () => {
    const deletePromises = [];

    if (resource === 'settlements') {
      const promises = data
        .filter((entry) => selectedRowKeys.includes(entry.id))
        .map((selectedRow) => {
          const customDataProvider = new UserSettlementsGroupS(
            selectedRow.userId
          );
          return customDataProvider.delete(selectedRow.id).catch((err) => {
            if (err && err.message === 'demo') {
              message.error(
                <MessagePopup type='error'>
                  Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                </MessagePopup>
              );
            }
          });
        });

      deletePromises.push(promises);
    } else if (resource === 'receipts') {
      const promises = data
        .filter((entry) => selectedRowKeys.includes(entry.id))
        .map((selectedRow) => {
          let customDataProvider;
          if (selectedRow.type === 'TENANT_CHANGE') {
            customDataProvider = new TenantChangesS(
              selectedRow.investmentId,
              selectedRow.premisesId
            );
          } else {
            customDataProvider = new tranchesService(
              selectedRow.investmentId,
              selectedRow.premisesId
            );
          }

          return customDataProvider.delete(selectedRow.id).catch((err) => {
            if (err && err.message === 'demo') {
              message.error(
                <MessagePopup type='error'>
                  Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                </MessagePopup>
              );
            }
          });
        });

      deletePromises.push(promises);
    } else if (resource === 'paymentsAGREEMENT') {
      const promises = data
        .filter((entry) => selectedRowKeys.includes(entry.id))
        .map((selectedRow) => {
          const customDataProvider = new PaymentsS(
            selectedRow.investmentId,
            selectedRow.premisesId,
            selectedRow.agreementId
          );
          return customDataProvider.delete(selectedRow.id).catch((err) => {
            if (err && err.message === 'demo') {
              message.error(
                <MessagePopup type='error'>
                  Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                </MessagePopup>
              );
            }
          });
        });

      deletePromises.push(promises);
    } else if (resource === 'faults') {
      const promises = data
        .filter((entry) => selectedRowKeys.includes(entry.id))
        .map((selectedRow) => {
          const customDataProvider = new FaultsS(selectedRow.investmentId);
          return customDataProvider.delete(selectedRow.id).catch((err) => {
            if (err && err.message === 'demo') {
              message.error(
                <MessagePopup type='error'>
                  Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                </MessagePopup>
              );
            }
          });
        });

      deletePromises.push(promises);
    } else if (resource === 'tenantChangeGroup') {
      const promises = data
        .filter((entry) => selectedRowKeys.includes(entry.id))
        .map((selectedRow) => {
          const customDataProvider = new TenantChangesS(
            selectedRow.investmentId,
            selectedRow.premisesId
          );
          return customDataProvider.delete(selectedRow.id).catch((err) => {
            if (err && err.message === 'demo') {
              message.error(
                <MessagePopup type='error'>
                  Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                </MessagePopup>
              );
            }
          });
        });

      deletePromises.push(promises);
    } else if (resource === 'premisesAll') {
      const promises = data
        .filter((entry) => selectedRowKeys.includes(entry.id))
        .map((selectedRow) => {
          const customDataProvider = new PermisesService(
            selectedRow.investmentId
          );
          return customDataProvider.delete(selectedRow.id).catch((err) => {
            if (err && err.message === 'demo') {
              message.error(
                <MessagePopup type='error'>
                  Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                </MessagePopup>
              );
            }
          });
        });

      deletePromises.push(promises);
    } else if (resource === 'faultGroup') {
      const promises = data
        .filter((entry) => selectedRowKeys.includes(entry.id))
        .map((selectedRow) => {
          const customDataProvider = new FaultGroupService(
            selectedRow.investmentId
          );
          return customDataProvider.delete(selectedRow.id).catch((err) => {
            if (err && err.message === 'demo') {
              message.error(
                <MessagePopup type='error'>
                  Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                </MessagePopup>
              );
            }
          });
        });

      deletePromises.push(promises);
    } else if (resource === 'resolutions') {
      const promises = data
        .filter((entry) => selectedRowKeys.includes(entry.id))
        .map((selectedRow) => {
          const customDataProvider = new ResolutionsService(
            selectedRow.investmentId
          );
          return customDataProvider.delete(selectedRow.id).catch((err) => {
            if (err && err.message === 'demo') {
              message.error(
                <MessagePopup type='error'>
                  Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                </MessagePopup>
              );
            }
          });
        });

      deletePromises.push(promises);
    } else if (resource === 'housingFaultGroup') {
      const promises = data
        .filter((entry) => selectedRowKeys.includes(entry.id))
        .map((selectedRow) => {
          const customDataProvider = new FaultGroupService(
            selectedRow.investmentId
          );
          return customDataProvider.delete(selectedRow.id).catch((err) => {
            if (err && err.message === 'demo') {
              message.error(
                <MessagePopup type='error'>
                  Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                </MessagePopup>
              );
            }
          });
        });

      deletePromises.push(promises);
    } else if (resource === 'documents') {
      const promises = data
        .filter((entry) => selectedRowKeys.includes(entry.id))
        .map((selectedRow) => {
          const customDataProvider = new documentsService(
            selectedRow.investmentId
          );
          return customDataProvider.delete(selectedRow.id).catch((err) => {
            if (err && err.message === 'demo') {
              message.error(
                <MessagePopup type='error'>
                  Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                </MessagePopup>
              );
            }
          });
        });

      deletePromises.push(promises);
    } else {
      deletePromises.push(
        dataProvider.deleteMany(selectedRowKeys).catch((err) => {
          if (err && err.message === 'demo') {
            message.error(
              <MessagePopup type='error'>
                Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
              </MessagePopup>
            );
          }
        })
      );
    }

    Promise.all(deletePromises)
      .then(() => {
        message.success(
          <MessagePopup type='success'>
            Pomyślnie usunięto rekord(y)
          </MessagePopup>
        );
        // refresh();
        history.go(0);
      })
      .catch(() => {
        message.error(
          <MessagePopup type='error'>
            Wystąpił błąd podczas usuwania rekordów
          </MessagePopup>
        );
      });
  };

  const showDeleteConfirm = () => {
    confirm({
      title: `Usunąć ${selectedRowKeys.length} pozycje?`,
      content: 'Po kliknięciu „Usuń”, przywrócenie nie będzie możliwe.',
      okText: 'Usuń',
      okType: 'danger',
      cancelText: 'Anuluj',
      icon: false,
      width: 320,
      className: 'delete-confirm-modal',
      cancelButtonProps: { className: 'button-secondary', size: 'large' },
      okButtonProps: { size: 'large' },
      onOk() {
        handleOk();
      },
    });
  };

  const SmsModal = () => {
    const [form] = Form.useForm();
    const [nrOfCharacters, setNrOfCharacters] = useState(0);
    const [smsContent, setSmsContent] = useState('');

    const sendSmsesHandler = async () => {
      if (smsContent.length !== 0) {
        try {
          const userIds = data
            .filter((entry) => selectedRowKeys.includes(entry.id))
            .map((selectedRow) => selectedRow.id);

          await massActionsService.sendSmses(smsContent, userIds);

          message.success(
            <MessagePopup type='success'>
              Wiadomość SMS wysłana poprawnie
            </MessagePopup>
          );
        } catch (err) {
          message.error(
            <MessagePopup type='error'>
              Nie udało się wysłać wiadomości SMS
            </MessagePopup>
          );
        }
        setShowSmsPopup(false);
      }
    };

    return (
      <Modal
        visible={showSmsPopup}
        onCancel={() => {
          setShowSmsPopup(false);
        }}
        className='mass-actions-sms'
        title='Wyślij wiadomość SMS'
        okText={'Wyślij'}
        okButtonProps={{
          className: 'ant-btn ant-btn-primary ant-btn-lg create-form-button',
          style: {
            color:
              smsContent.length === 0
                ? `rgba(255, 255, 255, 0.5)`
                : `rgba(255, 255, 255)`,
          },
        }}
        cancelText={'Anuluj'}
        cancelButtonProps={{
          className: 'ant-btn ant-btn-default ant-btn-lg button-secondary',
        }}
        closable={true}
        onOk={() => {
          sendSmsesHandler();
        }}
        maskClosable={true}
        mask={true}
        width={770}
        centerede={true}
        destroyOnClosee={true}
      >
        <Form form={form}>
          <Form.Item
            name='textareaField'
            rules={[{ required: true, message: 'To pole jest wymagane' }]}
          >
            <Input.TextArea
              maxLength={160}
              className='mass-actions-sms-textarea'
              placeholder='Treść wiadomości'
              onChange={(e) => {
                const inputText = e.target.value;

                setSmsContent(inputText);
                setNrOfCharacters(inputText.length);
              }}
            />
          </Form.Item>
        </Form>
        <div className='mass-actions-sms-signs-counter'>
          * Pozostało znaków: {nrOfCharacters}/160.
        </div>
        <div className='mass-actions-sms-annotation'>
          ** Wiadomość SMS zostanie wysłana tylko do użytkowników, którzy mają
          podany numer telefonu oraz wyrazili zgodę na otrzymywanie powiadomień.
        </div>
      </Modal>
    );
  };

  const EmailModal = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onEditorStateChange = (editorState) => {
      setEditorState(editorState);
    };

    const uploadCallback = (file) => {
      const formData = new FormData();
      formData.append('file', file);
      const publicFilesService = new PublicFileService();
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      return publicFilesService
        .create(formData)
        .then((response) => ({
          data: {
            link: `${baseUrl}${response.file}`,
          },
        }))
        .catch((err) => {
          if (err && err.message === 'demo') {
            message.error(
              'Dodawanie, usuwanie oraz edycja zablokowana w trybie demo'
            );
          }
        });
    };

    const editorConfig = {
      image: { uploadCallback },
    };

    const sendEmailsHandler = async () => {
      if (editorState.getCurrentContent().getPlainText('\u0001')) {
        try {
          const userIds = data
            .filter((entry) => selectedRowKeys.includes(entry.id))
            .map((selectedRow) => selectedRow.id);

          const emailContent = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
          );

          await massActionsService.sendEmails(emailContent.trim(), userIds);

          message.success(
            <MessagePopup type='success'>
              Wiadomość e-mail wysłana poprawnie
            </MessagePopup>
          );
        } catch (err) {
          message.error(
            <MessagePopup type='error'>
              Nie udało się wysłać wiadomości e-mail
            </MessagePopup>
          );
        }
        setShowEmailPopup(false);
      }
    };

    return (
      <Modal
        visible={showEmailPopup}
        onCancel={() => {
          setShowEmailPopup(false);
        }}
        getContainer={document.getElementById('root')}
        className='mass-actions-sms'
        title='Wyślij wiadomość e-mail'
        okText={'Wyślij'}
        okButtonProps={{
          className: 'ant-btn ant-btn-primary ant-btn-lg create-form-button',
          style: {
            color: editorState.getCurrentContent().getPlainText('\u0001')
              ? `rgba(255, 255, 255)`
              : `rgba(255, 255, 255, 0.5)`,
          },
        }}
        cancelText={'Anuluj'}
        cancelButtonProps={{
          className: 'ant-btn ant-btn-default ant-btn-lg button-secondary',
        }}
        closable={true}
        onOk={() => {
          sendEmailsHandler();
        }}
        maskClosable={true}
        mask={true}
        width={770}
        centerede={true}
        destroyOnClosee={true}
      >
        <div style={{ marginBottom: 20 }}>
          <Editor
            localization={{ locale: 'pl' }}
            editorState={editorState}
            wrapperClassName='demo-wrapper'
            editorClassName='demo-editor'
            onEditorStateChange={onEditorStateChange}
            toolbar={editorConfig}
          />
        </div>

        <div className='mass-actions-email-annotation'>
          * Wiadomość e-mail zostanie wysłana tylko do użytkowników, którzy mają
          podany adres e-mail oraz wyrazili zgodę na otrzymywanie powiadomień.
        </div>
        <Divider />
      </Modal>
    );
  };

  const menu = (
    <Menu>
      {!hideReset && (
        <Menu.Item
          key='1'
          onClick={async () => {
            try {
              const userIds = data
                .filter((entry) => selectedRowKeys.includes(entry.id))
                .map((selectedRow) => selectedRow.id);

              for (let id of userIds) {
                const password = generateRandomPassword(8);

                if (isAdmin) {
                  communityAdminsService
                    .sendPassword(id, { password })
                    .catch((err) => {
                      throw err;
                    });
                } else {
                  VendorsService.sendPassword(id, { password }).catch((err) => {
                    throw err;
                  });
                }
              }

              message.success(
                <MessagePopup
                  type='success'
                  annotation='Wysłaliśmy dane do logowania na adres e-mail przypisany do konta'
                >
                  Wysłano dane do logowania
                </MessagePopup>
              );
            } catch (err) {
              message.error(
                <MessagePopup type='error'>
                  Nie udało się zresetować hasła
                </MessagePopup>
              );
            }
          }}
          icon={<Icon name='access' style={{ marginRight: '5px' }} />}
        >
          Zresetuj hasło i wyślij
        </Menu.Item>
      )}
      {!hideSms && (
        <Menu.Item
          key='2'
          onClick={async () => {
            setShowSmsPopup(true);
          }}
          icon={<Icon name='comment' style={{ marginRight: '5px' }} />}
        >
          Wyślij SMS
        </Menu.Item>
      )}
      {!hideEmail && (
        <Menu.Item
          key='3'
          onClick={async () => {
            setShowEmailPopup(true);
          }}
          icon={<Icon name='email' style={{ marginRight: '5px' }} />}
        >
          Wyślij e-mail
        </Menu.Item>
      )}
      {showDownload && (
        <Menu.Item
          key='5'
          onClick={async () => {
            const selectedDocuments = data.filter((entry) =>
              selectedRowKeys.includes(entry.id)
            );

            for (let selectedDocument of selectedDocuments) {
              const DocumentsService = new documentsService(
                selectedDocument.investmentId
              );

              DocumentsService.getOne(selectedDocument.id)
                .then((data) => {
                  const a = document.createElement('a');
                  a.href = `data:application/octet-stream;base64,${data.content}`;
                  a.download = data.filename;
                  a.click();
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }}
          icon={<Icon name='download' style={{ marginRight: '5px' }} />}
        >
          Pobierz zaznaczone
        </Menu.Item>
      )}
      {showAssignOwners && (
        <Menu.Item
          key='6'
          onClick={() => {
            const premisesIds = data
              .filter((entry) => selectedRowKeys.includes(entry.id))
              .map((selectedRow) => selectedRow.id);

            let investmentId =
              id ?? JSON.parse(localStorage.getItem('choosenInvestmentId'));

            history.push(
              `/investment/${investmentId}/assignOwners?${premisesIds
                ?.map((id) => `premiseId=${id}`)
                .join('&')}`
            );
          }}
          icon={<Icon name='client' style={{ marginRight: '5px' }} />}
        >
          Przypisz właściciela
        </Menu.Item>
      )}
      {showAddCountersReads && (
        <Menu.Item
          key='7'
          onClick={() => {
            const premisesIds = data
              .filter((entry) => selectedRowKeys.includes(entry.id))
              .map((selectedRow) => selectedRow.id);

            let investmentId =
              id ?? JSON.parse(localStorage.getItem('choosenInvestmentId'));

            history.push(
              `/investment/${investmentId}/addCountersReads?${premisesIds
                ?.map((id) => `premiseId=${id}`)
                .join('&')}`
            );
          }}
          icon={<Icon name='timer' style={{ marginRight: '5px' }} />}
        >
          Dodaj odczyt licznika
        </Menu.Item>
      )}
      {showSendMembersMessage && (
        <Menu.Item
          key='8'
          onClick={() => {
            const membersIds = data
              .filter((entry) => selectedRowKeys.includes(entry.id))
              .map((selectedRow) => selectedRow.id);

            let investmentId =
              id ?? JSON.parse(localStorage.getItem('choosenInvestmentId'));

            history.push(
              `/investment/${investmentId}/messages/create?sendToType=MEMBERS&${membersIds
                ?.map((id) => `id=${id}`)
                .join('&')}`
            );
          }}
          icon={<Icon name='email' style={{ marginRight: '5px' }} />}
        >
          Wiadomość
        </Menu.Item>
      )}
      {showSendMembersTemplate && (
        <Menu.Item
          key='9'
          onClick={() => {
            let investmentId =
              id ?? JSON.parse(localStorage.getItem('choosenInvestmentId'));

            history.push(
              `/messages?investmentId=${investmentId}&isTemplate=true`
            );
          }}
          icon={
            <Icon
              name='template'
              style={{ marginRight: '5px', color: '#587999', fontSize: 10 }}
            />
          }
        >
          Wiadomość z szablonu
        </Menu.Item>
      )}
      {showSendPremisesMessage && (
        <Menu.Item
          key='10'
          onClick={() => {
            const premisesIds = data
              .filter((entry) => selectedRowKeys.includes(entry.id))
              .map((selectedRow) => selectedRow.id);

            let investmentId =
              id ?? JSON.parse(localStorage.getItem('choosenInvestmentId'));

            history.push(
              `/investment/${investmentId}/messages/create?sendToType=PREMISES&${premisesIds
                ?.map((id) => `id=${id}`)
                .join('&')}`
            );
          }}
          icon={<Icon name='email' style={{ marginRight: '5px' }} />}
        >
          Wiadomość
        </Menu.Item>
      )}
      {showSendPremisesTemplate && (
        <Menu.Item
          key='11'
          onClick={() => {
            let investmentId =
              id ?? JSON.parse(localStorage.getItem('choosenInvestmentId'));

            history.push(
              `/messages?investmentId=${investmentId}&isTemplate=true`
            );
          }}
          icon={
            <Icon
              name='template'
              style={{ marginRight: '5px', color: '#587999', fontSize: 10 }}
            />
          }
        >
          Wiadomość z szablonu
        </Menu.Item>
      )}
      {!hideDelete && (
        <Menu.Item
          key='4'
          onClick={showDeleteConfirm}
          style={{ color: '#EC434C' }}
          icon={<Icon name='delete' style={{ marginRight: '5px' }} />}
        >
          Usuń zaznaczone
        </Menu.Item>
      )}
    </Menu>
  );

  const exportRows = (type) => {
    const tempArray = cloneDeep(data);
    const rowsToExport = tempArray.filter((entry) =>
      selectedRowKeys.includes(entry.id)
    );
    const visibleColumns = columns.filter(
      (entry) => entry.visible && entry.export !== false
    );
    const columnsToKeep = visibleColumns.map((column) => column.key);
    const fields = visibleColumns.map((column) => ({
      label: column.exportTitle ?? column.title,
      value: column.key,
    }));

    const pdfFields = visibleColumns.map((column) => ({
      header: column.exportTitle ?? column.title,
      dataKey: column.key,
    }));

    const parsedData = rowsToExport.map((row) => {
      const temp = row;
      Object.keys(rowsToExport).forEach((key) => {
        if (!columnsToKeep.includes(key)) {
          delete temp[key];
        }
      });

      const exportRenderers = visibleColumns.filter(
        (entry) => entry.exportRender
      );

      if (!exportRenderers) {
        return temp;
      }

      exportRenderers.forEach((renderer) => {
        temp[renderer.key] = renderer.exportRender(temp[renderer.key], temp);
      });

      return temp;
    });

    const fileName = `${title}-${moment()
      .format('YYYY-MM-DD HH:mm:ss')
      .toString()}`;

    if (type === 'xls') {
      const csv = json2csv.parse(parsedData, { fields });
      const a = document.createElement('a');
      a.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      a.download = `${fileName}.csv`;
      a.click();
    } else {
      const doc = new jsPDF({
        orientation: 'landscape',
      });
      doc.setFont('Overpass-Regular');
      doc.text(title, 14, 20);
      doc.autoTable({
        columns: pdfFields,
        margin: { top: 10 },
        body: parsedData,
        tableWidth: 'auto',
        startY: 40,
        theme: 'striped',
        styles: {
          font: 'Overpass-Regular',
          fontStyle: 'normal',
        },
        headStyles: { fontStyle: 'normal' },
      });
      doc.save(`${fileName}.pdf`);
    }

    // history.go(0);
  };

  const exportMenu = (
    <Menu>
      <Menu.Item
        key='1'
        onClick={() => exportRows('xls')}
        icon={<Icon name='xls' style={{ marginRight: '5px' }} />}
      >
        XLS
      </Menu.Item>
      <Menu.Item
        key='2'
        onClick={() => exportRows('pdf')}
        icon={<Icon name='pdf' style={{ marginRight: '5px' }} />}
      >
        PDF
      </Menu.Item>
    </Menu>
  );

  return (
    <span>
      <SmsModal />
      <EmailModal />
      <Dropdown
        overlay={exportMenu}
        trigger='click'
        placement='bottomCenter'
        style={{ marginRight: '5px' }}
      >
        <Button className='button-secondary' style={{ marginRight: '5px' }}>
          <Icon name='download' />
        </Button>
      </Dropdown>
      {((canEdit && !hideDelete) || showDownload) && (
        <Dropdown overlay={menu} trigger='click' placement='bottomLeft'>
          <Button className='button-secondary' style={{ marginRight: 5 }}>
            Akcje <DownOutlined />
          </Button>
        </Dropdown>
      )}
    </span>
  );
};

export default MassActions;
