import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Form,
  Button,
  Checkbox,
  PageHeader,
  Row,
  Col,
  Card,
  Upload,
  message,
  Switch,
  Typography,
} from 'antd';
import moment from 'moment';

import communityAdminsService from '../../services/communityAdminsService';
import Icon from '../../components/Icon';
import deleteConfirm from '../../components/deleteConfirm';
import sendPassword from '../../static/images/img-1.png';
import sendPassword2 from '../../static/images/img-m-2.png';
import sendPassword3 from '../../static/images/img-m-3.png';
import sendPassword4 from '../../static/images/img-m-4.png';
import Input from '../../components/InputComponent';
import CountrySelect from '../../components/CountrySelect';
import CommunityAdminPackageForm from '../../components/accountEditComponents/CommunityAdminPackageForm';
import { generateRandomPassword } from '../../helpers/passwordHelpers';

import Tabs from '../../components/Tabs';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Spin } from '../../components';
import MessagePopup from '../../components/MessagePopup';
import ProgressModal from '../../components/ProgressModal';

const { InputNumber, MaskedInput, Password2, Mobile, Select } = Input;
const { Option } = Select;
const { Text } = Typography;
const { TabPane } = Tabs;

const Edit = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams();
  const packageFormRef = useRef();

  const location = useLocation();
  let defaultPane;
  if (location.state?.defaultPane) {
    defaultPane = location.state.defaultPane;
  }

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [userAvatar, setUserAvatar] = useState('');
  const [showCorrespondence, setShowCorrespondence] = useState(false);

  const [importProgress, setImportProgress] = useState(0);
  const [progressModalVisible, setProgressModalVisible] = useState(false);

  const history = useHistory();
  const [form] = Form.useForm();
  let image = sendPassword;
  const selectedTheme = localStorage.getItem('theme') || 'default';
  if (selectedTheme === 'sky') {
    image = sendPassword3;
  }
  if (selectedTheme === 'brick') {
    image = sendPassword2;
  }
  if (selectedTheme === 'mech') {
    image = sendPassword4;
  }

  useEffect(() => {
    setLoading(true);
    communityAdminsService.getOne(id).then((data) => {
      if (data?.identity?.idExpireDate) {
        // eslint-disable-next-line no-param-reassign
        data = {
          ...data,
          identity: {
            ...data.identity,
            idExpireDate: moment(data.identity.idExpireDate),
          },
        };
      }

      if (data?.packageExpireDate) {
        data.packageExpireDate = moment(data.packageExpireDate, 'YYYY-MM-DD');
      }

      setUserData(data);
      setUserAvatar(data.avatar);
      const defaultValues = data;

      defaultValues.canSell = true;
      defaultValues.canRent = false;

      if (data.roles && data.roles.includes('ROLE_TENANT')) {
        defaultValues.canRent = true;
      }
      if (
        data.communityAdminPermissions &&
        data.communityAdminPermissions.includes('HOUSING_ASSOCIATION')
      ) {
        defaultValues.housingAssociation = true;
      }
      if (
        data.communityAdminPermissions &&
        data.communityAdminPermissions.includes('HOUSING_COMMUNITY')
      ) {
        defaultValues.housingCommunity = true;
      }
      if (data.correspondenceAddress) {
        setShowCorrespondence(true);
        defaultValues.differentAddress = true;
      }

      const fillForm = async () => {
        await form.setFieldsValue(defaultValues);
        setLoading(false);
      };

      fillForm();
    });
  }, [form, id]);

  const onFinish = (values) => {
    const roles = ['ROLE_COMMUNITY_ADMIN'];

    let communityAdminPermissions = [];
    if (values.housingAssociation) {
      communityAdminPermissions.push('HOUSING_ASSOCIATION');
    }
    if (values.housingCommunity) {
      communityAdminPermissions.push('HOUSING_COMMUNITY');
    }
    if (communityAdminPermissions.length === 0) {
      communityAdminPermissions = null;
    }

    const data = { ...userData, ...values, roles, communityAdminPermissions };

    if (!data.differentAddress) {
      data.correspondenceAddress = null;
    }

    if (data?.packageExpireDate) {
      data.packageExpirationDate = moment(data.packageExpireDate).format(
        'YYYY-MM-DD'
      );
    }

    communityAdminsService
      .update(id, data)
      .then((response) => {
        packageFormRef.current.onPackageChangeFinish(
          data.packageExpirationDate
        );

        message.success(
          <MessagePopup type='success'>Zapisano pomyślnie</MessagePopup>
        );

        if (response.id) {
          history.push(`/communityAdmins/${response.id}/show`);
        }
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        } else if (err?.response?.status === 409) {
          form.setFields([
            {
              name: 'username',
              errors: ['Podany login juz istnieje'],
            },
          ]);
        } else {
          message.error(
            <MessagePopup type='error'>Wystąpił nieznany błąd</MessagePopup>
          );
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const uploadAvatar = (data) => {
    const formData = new FormData();
    formData.append('avatar', data.file);

    setProgressModalVisible(true);

    communityAdminsService
      .uploadAvatar(id, formData, {
        onUploadProgress: (progressEvent) => {
          setImportProgress((progressEvent.loaded / progressEvent.total) * 100);
        },
      })
      .then((response) => {
        setProgressModalVisible(false);
        message.success(
          <MessagePopup type={'success'}>Pomyślnie dodano avatar</MessagePopup>
        );

        setUserAvatar(response.avatar);
      })
      .catch((err) => {
        setProgressModalVisible(false);

        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(
        <MessagePopup type='error'>
          Dozwolone są tylko pliki graficzne
        </MessagePopup>
      );
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(
        <MessagePopup type='error'>Plik musi być mniejszy niz 2MB</MessagePopup>
      );
    }
    return isJpgOrPng && isLt2M;
  };

  const correspondenceShow = (event) => {
    setShowCorrespondence(event.target.checked);
  };

  const fillPasswordsHandler = () => {
    const randomPassword = generateRandomPassword(8);

    form.setFieldsValue({
      password: randomPassword,
      confirm: randomPassword,
    });
  };

  const sendPasswordAction = () => {
    const password = form.getFieldValue('password');
    const confirm = form.getFieldValue('confirm');

    if (password !== confirm) {
      message.error(
        <MessagePopup type='error' annotation={'Hasła muszą być takie same'}>
          Nie udało się zresetować hasła
        </MessagePopup>
      );
      return;
    }

    if (!password || password.length < 8) {
      message.error(
        <MessagePopup type='error' annotation={'Hasło jest zbyt krókie'}>
          Nie udało się zresetować hasła
        </MessagePopup>
      );
      return;
    }
    communityAdminsService
      .sendPassword(id, { password })
      .then(() => {
        message.success(
          <MessagePopup type='success'>Hasło zostało wysłane</MessagePopup>
        );
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='success'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const handleOk = () => {
    communityAdminsService
      .delete(id)
      .then(() => history.push('/communityAdmins'))
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const handleCancel = () => {
    history.push('/communityAdmins');
  };

  const HeaderTitle = () => {
    if (!userData.name) {
      return <span>Edycja</span>;
    }
    return (
      <span>
        Edytujesz: <span className='header-subtitle'>{userData.name}</span>
      </span>
    );
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <div className='form-create'>
      <ProgressModal
        progress={importProgress}
        isModalVisible={progressModalVisible}
      />
      <PageHeader
        ghost={false}
        backIcon={<Icon name='arrow-simply-left' />}
        onBack={() => history.push('/communityAdmins')}
        title={<HeaderTitle />}
      >
        <Form
          form={form}
          name='create'
          className='create-form'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <>
            <Card className='form-create-main-card'>
              <Tabs defaultActiveKey={defaultPane} tabPosition='left'>
                <TabPane tab='Informacje' key='form'>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className='form-section'>
                          <h2>Miniaturka</h2>
                          <Form.Item
                            name='dragger'
                            valuePropName='fileList'
                            getValueFromEvent={normFile}
                            noStyle
                          >
                            <Upload.Dragger
                              name='avatar'
                              listType='picture-card'
                              className='avatar-uploader'
                              showUploadList={false}
                              customRequest={uploadAvatar}
                              action='avatar'
                              beforeUpload={beforeUpload}
                            >
                              <div className='upload-avatar-wrapper'>
                                {userAvatar && (
                                  <img
                                    src={`${baseUrl}${userAvatar}`}
                                    alt='avatar'
                                  />
                                )}
                                {/* <Button
                                  size='large'
                                  className='change-button'
                                  type='primary'
                                >
                                  Zmień <Icon name='edit' />
                                </Button> */}
                              </div>
                            </Upload.Dragger>
                          </Form.Item>
                          <span
                            style={{
                              marginTop: '20px',
                              fontSize: '12px',
                              color: '#C0D6E5',
                            }}
                          >
                            Zalecany format plików: kwadrat
                          </span>
                        </div>
                        <div className='form-section'>
                          <h2>Administrator nieruchomości</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name='nameDeveloper'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Nazwa' />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className='form-section'>
                          <h2>Dane firmowe</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='name'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Nazwa firmy' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='nip'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input.MaskedInput
                                  mask='000-000-00-00'
                                  size='large'
                                  placeholder='NIP'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className='form-section'>
                          <h2>Adres</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'street']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Ulica' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item
                                name={['address', 'number']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Numer' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item name={['address', 'local']}>
                                <Input size='large' placeholder='Mieszkanie' />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'city']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Miejscowość' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'zip']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <MaskedInput
                                  mask='00-000'
                                  size='large'
                                  placeholder='Kod pocztowy'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'country']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <CountrySelect />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item>
                            <Form.Item
                              name='differentAddress'
                              valuePropName='checked'
                              noStyle
                            >
                              <Checkbox onChange={correspondenceShow}>
                                Inny adres do korespondencji
                              </Checkbox>
                            </Form.Item>
                          </Form.Item>
                          {showCorrespondence && (
                            <>
                              <h2>Adres korespondencyjny</h2>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'street']}
                                  >
                                    <Input size='large' placeholder='Ulica' />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={6}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'number']}
                                  >
                                    <Input size='large' placeholder='Numer' />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={6}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'local']}
                                  >
                                    <Input
                                      size='large'
                                      placeholder='Mieszkanie'
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'city']}
                                  >
                                    <Input
                                      size='large'
                                      placeholder='Miejscowość'
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'zip']}
                                  >
                                    <Input.MaskedInput
                                      mask='11-111'
                                      size='large'
                                      placeholder='Kod pocztowy'
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'country']}
                                  >
                                    <CountrySelect />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                        <div className='form-section'>
                          <h2>Dane kontaktowe</h2>
                          <Row gutter={[20, 24]}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='email'
                                rules={[
                                  {
                                    required: false,
                                    message: 'Proszę uzupełnić adres email',
                                  },
                                  {
                                    type: 'email',
                                    message: 'Niepoprawny adres email',
                                  },
                                ]}
                              >
                                <Input
                                  size='large'
                                  placeholder='Adres e-mail'
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='mobile'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Mobile size='large' placeholder='Telefon' />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className='form-section'>
                          <h2>Jak się o nas dowiedziałeś/-aś?</h2>
                          <Form.Item name='clientSource'>
                            <Select placeholder='Źródło klienta' size='large'>
                              <Option value='Wyszukiwarka internetowa'>
                                Wyszukiwarka internetowa
                              </Option>
                              <Option value='Ulotka informacyjna'>
                                Ulotka informacyjna
                              </Option>
                              <Option value='Z polecenia'>Z polecenia</Option>
                              <Option value='Media społecznościowe'>
                                Media społecznościowe
                              </Option>
                              <Option value='Reklama prasowa, billboardy'>
                                Reklama prasowa, billboardy
                              </Option>
                              <Option value='Inne'>Inne</Option>
                            </Select>
                          </Form.Item>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prev, curr) =>
                              prev.clientSource !== curr.clientSource
                            }
                          >
                            {({ getFieldValue }) =>
                              getFieldValue('clientSource') === 'Inne' && (
                                <>
                                  <Form.Item name='clientSourceDescription'>
                                    <Input
                                      size='large'
                                      placeholder='Inne źródło'
                                    />
                                  </Form.Item>
                                </>
                              )
                            }
                          </Form.Item>
                        </div>

                        <div className='permissions'>
                          <div className='form-section'>
                            <h2>Status konta</h2>
                            <Form.Item
                              label='Aktywny'
                              name='enabled'
                              valuePropName='checked'
                            >
                              <Switch />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Card className='create-form-password-card'>
                      <Row>
                        <Col xs={24} sm={{ span: 10, offset: 2 }}>
                          <div className='form-section'>
                            <h2>Dane do logowania</h2>
                            <Form.Item
                              name='username'
                              rules={[
                                {
                                  required: true,
                                  message: 'Proszę uzupełnić login',
                                },
                              ]}
                            >
                              <Input size='large' placeholder='Login' />
                            </Form.Item>
                            <Form.Item
                              name='password'
                              rules={[
                                { message: 'Pole wymagane' },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (
                                      /\d/.test(value) &&
                                      /[A-Z]/.test(value) &&
                                      /[a-z]/.test(value) &&
                                      value.length >= 8
                                    ) {
                                      return Promise.resolve();
                                    } else if (!value) {
                                      return Promise.resolve();
                                    }

                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    return Promise.reject(
                                      'Hasło nieprawidłowe'
                                    );
                                  },
                                }),
                              ]}
                            >
                              <Password2 size='large' placeholder='Hasło' />
                            </Form.Item>
                            <Form.Item
                              name='confirm'
                              dependencies={['password']}
                              hasFeedback
                              rules={[
                                {
                                  message: 'Potwierdź hasło',
                                },
                                {
                                  min: 8,
                                  message:
                                    'Hasło musi posiadać conajmniej 8 znaków.',
                                },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (
                                      !value ||
                                      getFieldValue('password') === value
                                    ) {
                                      return Promise.resolve();
                                    }
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    return Promise.reject(
                                      'Hasło nieprawidłowe'
                                    );
                                  },
                                }),
                              ]}
                            >
                              <Password2
                                size='large'
                                placeholder='Powtórz hasło'
                              />
                            </Form.Item>
                            <div
                              className='account-subscription-details-limits-addnotation-details'
                              style={{ color: '#C0D6E5', marginBottom: 24 }}
                            >
                              * Min. 8 znaków, wielka i mała litera, jedna cyfra
                            </div>
                            <Form.Item>
                              <Text
                                type='secondary'
                                className='generate-password'
                                underline
                                onClick={fillPasswordsHandler}
                              >
                                Generuj hasło
                              </Text>
                            </Form.Item>

                            <Form.Item>
                              <Button
                                className='create-form-button-send-password'
                                size='large'
                                onClick={() => sendPasswordAction()}
                              >
                                Wyślij login i hasło <Icon name='access' />
                              </Button>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={0} sm={10}>
                          <div className='send-password-image-wrapper'>
                            <img src={image} alt='send password icon' />
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </TabPane>

                <TabPane tab='Uprawnienia' key='permissions' forceRender>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className='permissions'>
                          <div className='form-section'>
                            <h2>Uprawnienia</h2>
                            <Form.Item
                              label='Spółdzielnie'
                              name='housingAssociation'
                              valuePropName='checked'
                            >
                              <Switch />
                            </Form.Item>
                            <Form.Item
                              label='Wspólnoty'
                              name='housingCommunity'
                              valuePropName='checked'
                            >
                              <Switch />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPane>

                <TabPane tab='Subskrypcja' key='packages' forceRender>
                  <CommunityAdminPackageForm
                    ref={packageFormRef}
                    userData={userData}
                    isAdmin={true}
                  />
                </TabPane>
              </Tabs>
              <Button
                className='delete-btn'
                type='danger'
                onClick={() => deleteConfirm(handleOk)}
              >
                <Icon name='delete' style={{ marginRight: '5px' }} /> Usuń
              </Button>
            </Card>
            <Row className='form-actions' justify='space-between'>
              <Col sm={4} className='form-actions-cancel-wrapper'>
                <Button
                  className='button-secondary'
                  size='large'
                  onClick={handleCancel}
                >
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className='form-actions-save-wrapper'>
                <Form.Item>
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    className='create-form-button'
                  >
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};

export default Edit;
